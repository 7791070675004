import { http } from '@/http/axios.js'
import axios from 'axios'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { dateFormat } from '@/utils/index.js'
import { showScreenLoading, hideScreenLoading } from '@/http/loading.js'
import { Message } from 'element-ui'

// 列表
export function getListAPI(params) {
  return http({
    url: '/admin/order/page',
    method: 'get',
    params
  })
}
// 详情
export function getDetailAPI(orderNo) {
  return http({
    url: '/admin/order/detail',
    method: 'get',
    params: { orderNo }
  })
}

// 发货
export function handleDelieryAPI(data) {
  return http({
    url: '/admin/order/delivery',
    method: 'post',
    data
  })
}
// 自提发货
export function handleDeliveryStoreAPI(data) {
  return http({
    url: '/admin/order/pickup',
    method: 'post',
    data
  })
}
// 虚拟发货
export function handleDelieryVirtualAPI(data) {
  return http({
    url: '/admin/order/virtualDelivery',
    method: 'post',
    data
  })
}
// 订单账单列表
export function getOrderBillListAPI(orderNo) {
  return http({
    url: '/admin/order/bill/list',
    method: 'get',
    params: { orderNo }
  })
}
// 订单取消
export function handleOrderCancelAPI(orderNo) {
  return http({
    url: '/admin/order/cancel',
    method: 'post',
    data: { orderNo }
  })
}
// 下载合同
export function downloadOrderContractAPI(orderNo) {
  return http({
    url: '/admin/order/contract/download',
    method: 'post',
    data: { orderNo }
  })
}
export function downloadFileByUrl(url) {
  console.log(123)
  showScreenLoading()
  let link = document.createElement('a')
  //创建一个a标签
  link.style.display = 'none'
  //将a标签隐藏
  link.href = url
  //给a标签添加下载链接  "域名+接口"  safe是一个动态的域名  后面的接口替换成你自己的下载接口
  // link.setAttribute('download', dateFormat())
  link.download = '123.pdf'
  // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
  document.body.appendChild(link)
  //将上面创建的a标签加入到body的尾部
  link.click()
  document.body.removeChild(link)
  //执行a标签
  hideScreenLoading()
}
// 统计
export function getOrderStaticAPI() {
  return http({
    url: '/admin/order/status/stat',
    method: 'get'
  })
}
// 三方
export function getOrderRiskAPI(memberId) {
  return http({
    url: '/admin/member/openRisk/data',
    method: 'get',
    params: { memberId }
  })
}
// 机审
export function getRiskReviewAPI(orderNo) {
  return http({
    url: '/admin/order/riskReview/data',
    method: 'get',
    params: { orderNo }
  })
}
// 合同详情
export function getContractDetailAPI(orderNo) {
  return http({
    url: '/admin/order/contract/detail',
    method: 'get',
    params: { orderNo }
  })
}
// 全部导出
export function DownloadAPI(data) {
  showScreenLoading()
  axios({
    method: 'post',
    url: '/api/admin/order/export',
    responseType: 'blob',
    data,
    headers: {
      Authorization: getToken() || ''
    }
  })
    .then(async res => {
      hideScreenLoading()
      let data = res.data
      console.log(res)
      // if (!data || data.type !== 'application/vnd.ms-excel') {
      //   let response = await new Response(data).text()
      //   response = JSON.parse(response)
      //   Message.error(response?.message || '导出失败')
      //   return
      // }
      let url = window.URL.createObjectURL(new Blob([data]))
      let a = document.createElement('a')
      a.style.display = 'none'
      a.href = url
      let filename = `订单列表${dateFormat()}.xlsx`
      a.setAttribute('download', decodeURIComponent(filename))
      document.body.appendChild(a)
      a.click() //执行下载
      window.URL.revokeObjectURL(a.href) //释放url
      document.body.removeChild(a) //释放标签
    })
    .catch(error => {
      hideScreenLoading()
    })
}

// 下载文件1
export function downFile(url, name) {
  getBlob(url).then(blob => {
    saveAs(blob, name)
  })
}
function getBlob(url) {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status == 200) {
        resolve(xhr.response)
      }
    }
    xhr.send()
  })
}
function saveAs(blob, name) {
  var link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = name
  link.click()
}
// 下载文件2
export function downloadPDF(pdfUrl, name) {
  showScreenLoading()
  axios({
    url: pdfUrl,
    method: 'GET',
    responseType: 'blob' // 必须指定为blob类型才能下载
  }).then(
    res => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      hideScreenLoading()
    },
    reason => {
      hideScreenLoading()
      Message.error('下载失败！')
    }
  )
}
// 完成订单
export function handleCompleteAPI(data) {
  return http({
    url: '/admin/order/complete',
    method: 'post',
    data
  })
}
// 首付明细
export function getOfflinePayDetailAPI(orderNo) {
  return http({
    url: '/admin/order/prepay/detail',
    method: 'get',
    params: { orderNo }
  })
}
// 提交首付
export function submitOfflinePayAPI(orderNo) {
  return http({
    url: '/admin/order/prepay/offlinePay',
    method: 'post',
    data: { orderNo }
  })
}
// 提交首付
export function getKamiDetailAPI(orderNo) {
  return http({
    url: '/admin/order/card/info',
    method: 'get',
    params: { orderNo }
  })
}
// 撞库
export function hitAPI(orderNo) {
  return http({
    url: '/admin/order/roster/hit',
    method: 'post',
    data: { orderNo }
  })
}